/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

var app = app || {};

var spBreak = 767;

app.init = function () {
  app.btnMenu();
  app.navFit();
  app.smoothScroll();
  app.changeViewport();
  app.sliderKeyvisual();
  app.toggleActive();
  app.toggleNex();
  app.toggleParentnext();
  app.sliderDetail();
  app.scrollBar();
  app.placeHolder();
  app.clickFancy();
  app.matchHieght();
  app.addRow();
  app.loadMore();
  app.stickSidebar();
};

app.isMobile = function () {
  return window.matchMedia('(max-width: ' + spBreak + 'px)').matches;
};

app.isOldIE = function () {
  return $('html.ie9').length || $('html.ie10').length;
};

app.scrollBar = function(){
  $('.scrollbar').mCustomScrollbar();
};

app.navFit = function() {
  function navHeight() {
    var navi = $('#navi-sm');
    var navi_inner = $('#navi-sm .cover');
    if ( navi_inner.height() >= window.innerHeight ) {
      navi.css({
        'height': window.innerHeight - 46 + 'px'
      });
    }
    else
    {
      navi.css({
        'height': 'auto'
      });
    }
  }
  navHeight();
  $(window).on('load resize', function() {
    navHeight();
  });
};
app.smoothScroll = function() {
  $("a.scroll").click(function() {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("body,html").animate({
      scrollTop: position
    }, speed, "swing");

    return false;
  });
};
app.btnMenu = function(){
  offsetY =  window.pageYOffset;
  $('#btn-menu').click(function(){
    if ($(this).hasClass('active') ) {
      $(this).removeClass('active');
      $("#navi-sm").removeClass('active');
      $('#overlay').removeClass('active');
      $('body').removeClass('full');
      $('body').css('position', 'static');
      $(window).scrollTop(offsetY);
    }
    else{
      $(this).addClass('active');
      $("#navi-sm").addClass('active');
      $('#overlay').addClass('active');
      offsetY =  window.pageYOffset;
        $('body').css({
            position: 'fixed',
            'top': -offsetY + 'px'
        });
    }
  });
  $('#overlay').click(function(){
    $('#btn-menu').removeClass('active');
    $('#navi-sm').removeClass('active');
    $(this).removeClass('active');
    $('body').css('position', 'static');
    $(window).scrollTop(offsetY);
  });
};


app.sliderKeyvisual = function(){
  $('.slider-keyvisual').slick({
    dots: true,
    infinite: true,
    speed: 800,
    initialSlide: 0,
    slidesToShow: 1,
    prevArrow: '<a class="prev" href="#"><img src="/arrow-left.png" alt="prev" /></a>',
    nextArrow: '<a class="next" href="#"><img src="/arrow-right.png" alt="next" /></a>',
    centerMode: true,
    centerPadding: '0px',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          variableWidth: false,
          centerPadding: '0px',
          arrows: false
        }
      },
    ]
  });
};

app.sliderDetail = function(){
  $('.js-slider-detail').slick({
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          variableWidth: false,
          centerPadding: '0px',
          arrows: false
        }
      },
    ]

  });
};

app.toggleActive = function (){
  $('.tg-active').click(function(){
    $(this).toggleClass('active');
  });
};

app.toggleNex = function (){
  $('.js-head-next-active').click(function(){
    $(this).next().stop().slideToggle();
  });
};

app.toggleParentnext = function (){
  $('.js-btn-next-active').click(function(){
    $(this).next().stop().slideToggle();
    $(this).toggleClass('active');
  });
};

app.changeViewport = function() {
  var viewport_meta = document.getElementById('viewport');
  var viewports = {
    default: viewport_meta.getAttribute('content'),
    landscape: 'width=1160'
  };
  var viewport_set = function() {
    if ($('html').hasClass('tablet'))
      viewport_meta.setAttribute('content', viewports.landscape);
    else
      viewport_meta.setAttribute('content', viewports.default);
  };
  viewport_set();
  window.onresize = function() {
    viewport_set();
  };
};
app.placeHolder = function(){
  if ($('.placeholder-js').length > 0){
    $('.placeholder-js').placeholder();
  }
};
app.clickFancy = function(){
  $('.js-fancy').fancybox({
    // Options will go here
  });
};
app.matchHieght = function(){
  $('.item-match-height').matchHeight();
};
app.addRow = function(){
  $('#btn-add-row').click(function(){
    var markup = "<tr><td class='text'>ルーム名ルーム名ルーム名ルーム名ルーム名ルーム...</td><td class='num-fav'><a class='num' href='#'>1,000</a></td><td class='num-art'><a class='num' href='#'>15</a></td><td><a class='btn-tb trans' href='#'>編集</a><a class='btn-tb trans' href='#'>記事追加</a><a class='btn-tb trans stop' href='#'>停止</a></td></tr>";
    $('#table-admin tbody').append(markup);
    return false;
  });
};
app.loadMore = function() {
  size_li = $('#list-room li').length;
  x=3;
  $('#list-room li:lt('+x+')').show();
  $('#loadMore').click(function () {
    x= (x+2 <= size_li) ? x+2 : size_li;
    $('#list-room li:lt('+x+')').show();
    if(size_li <= x){
      $('#loadMore').addClass('hidden');
    }
    return false;
  });

  size_li_sm = $('#list-room-sm li').length;
  x=3;
  $('#list-room-sm li:lt('+x+')').show();
  $('#loadMore-sm').click(function () {
    x= (x+2 <= size_li_sm) ? x+2 : size_li_sm;
    $('#list-room-sm li:lt('+x+')').show();
    if(size_li_sm <= x){
      $('#loadMore-sm').addClass('hidden');
    }
    return false;
  });

};

app.stickSidebar = function() {
  if ($('#navi-stick').length > 0) {
    var o = $("#navi-stick");
    var offset = o.offset();
    var topPadding = 30;
    $(window).scroll(function() {
      if ($(window).scrollTop() > offset.top) {
        o.stop().animate({
          top: $(window).scrollTop() - offset.top
        },200);
      } else {
        o.stop().animate({
          top: 0
        },200);
      }
    });
  }
};

$(function () {
  app.init();
});

// 対象のセレクトタグ切り替え
$(function() {
  $('#selected_target').on('change', function() {
    $(this).parents('form').submit()
    return
  })
})

// 分野のセレクトタグ切り替え
$(function() {
  $('#selected_tag').on('change', function() {
    $(this).parents('form').submit()
    return
  })
})

// 分野のセレクトタグ切り替え
$(function() {
  $('#selected_sort').on('change', function() {
    $(this).parents('form').submit()
    return
  })
})

// 分野のセレクトタグ切り替え
$(function() {
  $('#selected_status').on('change', function() {
    $(this).parents('form').submit()
    return
  })
})

// 特集のセレクトタグ切り替え
$(function() {
  $('#selected_special_order').on('change', function() {
    $(this).parents('form').submit()
    return
  })
})
